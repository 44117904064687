<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="(isNew) ? title : item.user.name" back="Organisation.Team.Members" :readonly="$authorised('org/team/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid || model.used" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew && !isFounder && !isSelf" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-summary v-if="!isNew">

			<app-content-summary-item label="Name" :grow="true" :value="item.user.name" />
			<app-content-summary-item label="E-mail" :value="item.user.email" />
			<app-content-summary-item label="Level" :value="$constants.team.members.typeName[item.type]" />

		</app-content-summary>

		<app-content-box :readonly="$authorised('org/team/access', 'read')" v-if="isNew">

			<app-input-account label="E-mail" type="team" :text="emailMessages" v-model="model.email" v-on:exists="onExistsChange" :validation="$v.model.email" />
			<app-input-text :disabled="$v.model.email.$invalid || model.exists" label="Firstname" placeholder="Enter firstname" v-model="model.firstname" :validation="$v.model.firstname" />
			<app-input-text :disabled="$v.model.email.$invalid || model.exists" label="Lastname" placeholder="Enter lastname" v-model="model.lastname" :validation="$v.model.lastname" />
			<app-input-toggle label="Level" v-model="model.type" :options="$constants.team.members.typeName" notes="A convention level team member will not have access to any organization level permissions." />

		</app-content-box>

		<app-content-box :readonly="$authorised('org/team/access', 'read')" title="Organization permissions" icon="organisation" v-if="model.type !== $constants.team.members.type.convention">

			<app-input-toggle :disabled="isFounder" label="Conventions" :options="options.reduced" v-model="model.permissions.organisation.conventions.access" />
			<app-input-toggle :disabled="isFounder" label="Library" :options="options.reduced" v-model="model.permissions.organisation.library.access" />
			<app-input-toggle :disabled="isFounder" label="Billing" :options="options.reduced" v-model="model.permissions.organisation.billing.access" />
			<app-input-toggle :disabled="isFounder" label="Team" :options="options.reduced" v-model="model.permissions.organisation.team.access" />
			<app-input-toggle :disabled="isFounder" label="Settings" :options="options.reduced" v-model="model.permissions.organisation.settings.access" />

		</app-content-box>

		<app-content-box :readonly="$authorised('org/team/access', 'read')" title="Convention permissions" icon="convention">

			<app-input-toggle :disabled="isFounder" label="Announcements" :options="options.reduced" v-model="model.permissions.convention.announcements.access" />
			<app-input-toggle :disabled="isFounder" label="Badges" :options="options.reduced" v-model="model.permissions.convention.badges.access" />
			<app-input-toggle :disabled="isFounder" label="Pages" :options="options.reduced" v-model="model.permissions.convention.pages.access" />
			<app-input-toggle :disabled="isFounder" label="Registration" :options="options.reduced" v-model="model.permissions.convention.registration.access" />
			<app-input-toggle :disabled="isFounder" label="Schedule" :options="options.reduced" v-model="model.permissions.convention.schedule.access" />
			<app-input-toggle :disabled="isFounder" label="Checkout" :options="options.reduced" v-model="model.permissions.convention.checkout.access" />
			<app-input-toggle :disabled="isFounder" label="Library" :options="options.reduced" v-model="model.permissions.convention.library.access" />
			<app-input-toggle :disabled="isFounder" label="Assistant" :options="options.reduced" v-model="model.permissions.convention.assistant.access" />
			<app-input-toggle :disabled="isFounder" label="Billing" :options="options.reduced" v-model="model.permissions.convention.billing.access" />
			<app-input-toggle :disabled="isFounder" label="Settings" :options="options.reduced" v-model="model.permissions.convention.settings.access" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { email, requiredIf } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			options: {
				default: {
					0: 'None',
					1: 'Read only',
					2: 'Restricted',
					3: 'Full'
				},
				reduced: {
					0: 'None',
					1: 'Read only',
					3: 'Full'
				}
			},
			emailMessages: {
				info: 'If the prospective team member already has a Cardboard Events account then you should use that account\'s e-mail address to link the organization with their profile.',
				added: 'This e-mail address is associated with a Cardboard Events account that is already part of the team.',
				exists: 'This e-mail address is already associated with an existing Cardboard Events account. They will be added as a team member.',
				register: 'A Cardboard Events login will be generated for this e-mail address and will be added as a team member. The login credentials will automatically be e-mailed to the user.'
			},
			model: {
				id: false,
				exists: false,
				used: false,
				email: '',
				firstname: '',
				lastname: '',
				type: 1,
				permissions: {
					organisation: {
						conventions: {
							access: 0
						},
						library: {
							access: 0
						},
						billing: {
							access: 0
						},
						team: {
							access: 0
						},
						settings: {
							access: 0
						}
					},
					convention: {
						announcements: {
							access: 0
						},
						badges: {
							access: 0
						},
						pages: {
							access: 0
						},
						registration: {
							access: 0
						},
						schedule: {
							access: 0
						},
						checkout: {
							access: 0
						},
						library: {
							access: 0
						},
						billing: {
							access: 0
						},
						team: {
							access: 0
						},
						settings: {
							access: 0
						},
						assistant: {
							access: 0
						}
					}
				}
			}
		}

	},

	validations: {
		model: {
			email: {
				required: requiredIf('isNew'),
				email
			}
		}
	},

	computed: {

		isFounder: function() {

			return this.item.type === this.$constants.team.members.type.founder

		},

		isSelf: function() {

			return this.item.id === this.$id

		}

	},

	methods: {

		onExistsChange: function(e) {

			this.model.exists = e.exists
			this.model.used = e.used
			this.model.firstname = e.firstname || this.model.firstname
			this.model.lastname = e.lastname || this.model.lastname

		}

	}

}

</script>

<style scoped>

</style>